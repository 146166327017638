import ImageGrid from './ImageGrid.svelte';

document.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll('.product-image-grid').forEach((el) => {
		new ImageGrid({
			target: el,
			props: {
				url: el.dataset.url,
				images: parseInt(el.dataset.images),
				interval: parseInt(el.dataset.interval),
				columns: parseInt(el.dataset.columns)
			}
		});
	});
});
