<script>
	import _ from 'lodash';
	import axios from 'axios';
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';

	export let url = '';
	export let images = 3;
	export let interval = 6000;
	export let fadeDuration = 700;
	export let columns = 3;

	let elements = [],
		current = [],
		queue = [],
		loops = [],
		cached = [];

	onMount(() => {
		axios.get(`${url}?action=pde_product_images`)
			.then(response => {
				elements = response.data.data.photos;
				console.log(elements);
				current = elements.slice(0,images);
				console.log(current);
				cached = elements.slice(0,images);
				queue = elements.slice(images, elements.length);
				console.log(queue);
			}).catch((error) => {
				console.log(error);
				elements = [];
			}).finally(() => {
				startLoops();
			});
	});

	let startLoops = async () => {
		for (let i in current) {
			// let delay = i*1000;
			let delay = Math.random() * 10000;
			loops[i] = setTimeout(() => {
				setInterval(swap, interval, i);
			}, delay);
			setTimeout(() => {
				swap(i);
			}, delay);
		}
	}

	let swap = async (i) => {
		if (!queue.length) return;
		let next = queue.shift();
		if (!next) return;

        let replacement = current[i];

		if (cached.indexOf(next) === -1) {
			await axios.get(next.guid);
			cached.push(next);
		}
		queue.push(replacement);

        current[i] = null;
		setTimeout(() => {
			current[i] = next;
		}, fadeDuration + 50);
	}

</script>

<div id="product-gallery">
	{#if current.length > 0}
	<div class="images" style={ `grid-template-columns: repeat(${columns}, 1fr)` }>
		{#each current as image}
			<div class="image">
                {#if null !== image}
					<img src={image.guid} alt={image.post_title}
						transition:fade={{ duration: fadeDuration }}
					/>
                {/if}
			</div>
		{/each}
	</div>
	{/if}
</div>
